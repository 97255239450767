<template>
  <div>
    <v-container grid-list-xs>
      <h1 class="display-2 b text-center">Happy Customers</h1>
      <p class="body-1 text-center">
        Our Customers feel happier. They are enjoying happy and joyful working
        day with our superior quality Coffee.
      </p>

      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 md5 lg5 pa-2 class="slider">
          <v-carousel
            height="100%"
            hide-delimiter-background
            :show-arrows="false"
            cycle
          >
            <v-carousel-item v-for="(item, i) in items" :key="i">
              <div class="text-center">
                <v-avatar size="60">
                  <v-img
                    contain
                    lazy-src="https://picsum.photos/id/11/10/6"
                    height="100%"
                    width="100%"
                    :src="item.img"
                  ></v-img>
                </v-avatar>
              </div>
              <h1 class="body-2 b text-center">{{ item.title }}</h1>
              <p class="body-2 text-center">{{ item.desc }}</p>
            </v-carousel-item>
          </v-carousel>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          img: require("../../assets/w1.jpg"),
          title: "Wasihun Belay",
          desc: `Artist Wasihun Belay stopped by our café 
                this week to sample the best of Tesfa Coffee. We are honored 
                to have hosted him and we appreciate his support to the work 
                we are doing. Hope you'll stop by again, Wasihun - you have yet 
                to try our Macchiato, and that one will be on us. `,
        },
        {
          img: require("../../assets/m1.jpg"),
          title: "Mesfin Tsegaye",
          desc: `The most unique coffee I ever tasted in Ethiopia!
                 I know many Coffee In Addis Ababa, but Tesfa Coffee is unique. 
                 I like drinking pure coffee (without sugar). I always drink at 
                 least two cup of coffee every day. The unique and quality 
                 culturally prepared Ethiopian Coffee makes me feel happy to 
                 enjoy it very day.`,
        },
      ],
    };
  },
};
</script>

<style lang="css">
.slider .v-btn__content {
  color: #01ae53;
  top: 15px;
}
</style>