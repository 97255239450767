<template>
  <div>
    <v-container grid-list-xs>
      <v-layout row wrap justify-center>
        <v-flex xs12 md10 pa-2>
          <h1 class="display-2 b">Why Tesfa Coffee?</h1>
          <p class="body-1">
            Our vision is to provide an excellent coffee product to customers
            and provide a real value to the world
          </p>
        </v-flex>
        <v-flex xs12 sm12 md3 lg3 pa-2>
          <v-img
            contain
            lazy-src="https://picsum.photos/id/11/10/6"
            height="100%"
            width="100%"
            src="../../assets/c3.jpg"
          ></v-img>
        </v-flex>

        <v-flex xs12 sm12 md7 lg7 pa-2>
          <p class="body-1">
            At Tesf Coffee, Customers are served in a way they feel. We do not
            roast a different coffee species, but we roast them differently
            through our scientifically investigated roasting techniques to
            provide consistent product and services to our valuable customers.
            We are dedicated to creating the highest quality coffee products
            with exceptional brands of any size. Our goal is to consistently
            provide our customers with extraordinary services, offering fast,
            reliable turnarounds with low minimums and high volume capacity.
          </p>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-start>
        <v-flex xs12 sm12 md6 lg6 pa-2>
          <v-card height="100%" flat>
            <v-img
              contain
              lazy-src="https://picsum.photos/id/11/10/6"
              width="100%"
              src="https://cdn.standardmedia.co.ke/images/monday/kenyans_lack_of_a60c7758113f70.jpg"
            ></v-img>
            <v-card-text>
              <h1 class="title primaryDark--text b">
                Coffee Origin Identification
              </h1>
              <p class="body-1">
                Identifying the right coffee origin directly from farmers is our
                primary priority. The four mostly widely used coffee origins at
                Tesfa Coffee are: Nekemt, Yirga Cheffe, Guji, and Jimma Coffee.
              </p>
              <v-btn larg color="primaryDark" dark elevation="5"
                >Learn More Now</v-btn
              >
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 pa-2>
          <v-card height="100%" flat>
            <div v-for="(item, i) in items" :key="i">
              <v-layout row wrap justify-start align-start>
                <v-flex xs12 sm12 md4 lg4 pa-2>
                  <v-img
                    lazy-src="https://picsum.photos/id/11/10/6"
                    width="100%"
                    height="100%"
                    max-height="100px"
                    :src="item.img"
                    contain
                  ></v-img>
                </v-flex>
                <v-flex xs12 sm12 md8 lg8 pa-2>
                  <h1 class="title b primaryDark--text">
                    {{ item.title }}
                  </h1>
                  <p class="body-2">
                    {{ item.desc }}
                    <!-- <router-link class="link" to="">Read More</router-link> -->
                  </p>
                </v-flex>
              </v-layout>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          img: "https://image.shutterstock.com/image-photo/sorting-fresh-cherry-coffee-beans-260nw-1733029160.jpg",
          title: "Purifications",
          desc: `Once the right coffee origin is identified, we invest time on 
          identifying defects, sorting and purification of raw coffee to end 
          up with a best quality coffee.`,
        },
        {
          img: "https://www.drinkcoffee.com/wp-content/uploads/The-Best-Locally-Roasted-Coffee-in-NYC-Our-Favorites.jpg",
          title: "Roasting and Grinding",
          desc: `We have our own investigated technique and formula of Coffee 
          roasting and grinding that keeps consistent taste for our customers.
          We also roast and grind based on our customers' preference.`,
        },
        {
          img: require("../../assets/product/p1.png"),
          title: "Packing",
          desc: `Our coffee is packed with a high quality Almunium coated foil
          that keeps the coffee taste and quality consistent for more than 2 years.`,
        },
        {
          img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_g54AnZFGAUbDEfn-V_UkpuGnBAS9657VNw&usqp=CAU",
          title: "Boiling Techniques",
          desc: `We invented our own way of Boiling delicious Coffee 
          that keeps you smile and brighten your day. We serve our 
          coffee in an exceptionally suitable environment.`,
        },
      ],
    };
  },
};
</script>

