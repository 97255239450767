<template>
  <div>
    <section class="d-none d-md-flex">
      <v-toolbar color="primaryDark" flat dense>
        <a
          class="link white--text ml-4"
          href="https://www.linkedin.com/company/tesfa-coffee/"
          target="_blank"
          ><v-icon color="white">mdi-linkedin</v-icon> Lenkedin</a
        >
        <v-spacer></v-spacer>
        <a
          class="link white--text"
          href="https://www.facebook.com/tesfaa.coffee"
          target="_blank"
          ><v-icon color="white">mdi-facebook</v-icon> Facebook</a
        >
        <v-spacer></v-spacer>
        <a
          class="link white--text"
          href="mailto:info@tesfacoffee.com"
          target="_blank"
          ><v-icon color="white">mdi-email-newsletter</v-icon>
          info@tesfacoffee.com</a
        >

        <v-spacer></v-spacer>
        <a
          class="link white--text"
          href="tel:+251 91 135
          6334"
          ><v-icon color="white">mdi-phone-dial</v-icon> +251 91 135 6334</a
        >
        <v-spacer></v-spacer>
        <a
          class="link white--text mr-4"
          href="https://www.google.com/maps/place/Tesfa+Coffee+%7C+%E1%89%B0%E1%88%B5%E1%8D%8B+%E1%89%A1%E1%8A%93/@9.0384938,38.7628791,15z/data=!4m5!3m4!1s0x0:0xa54ca2c0b5fabe73!8m2!3d9.0384908!4d38.7628799"
          target="_blank"
          ><v-icon color="white">mdi-google-maps</v-icon> 5 Kilo,Addis
          Ababa,Ethiopia</a
        >
      </v-toolbar>
    </section>

    <section class="d-flex d-md-none">
      <v-toolbar
        flat
        dense
        style="border-bottom: 1px solid #e6e6e6; background-color: white"
      >
        <a
          class="link white--text ml-4"
          href="https://www.linkedin.com/company/tesfa-coffee/"
          target="_blank"
          ><v-icon color="primaryDark">mdi-linkedin</v-icon></a
        >
        <v-spacer></v-spacer>
        <a
          class="link white--text"
          href="https://www.facebook.com/tesfaa.coffee"
          target="_blank"
          ><v-icon color="primaryDark">mdi-facebook</v-icon></a
        >
        <v-spacer></v-spacer>
        <a
          class="link white--text"
          href="mailto:info@tesfacoffee.com"
          target="_blank"
          ><v-icon color="primaryDark">mdi-email-newsletter</v-icon>
        </a>

        <v-spacer></v-spacer>
        <a
          class="link white--text"
          href="tel:+251 91 135
          6334"
          ><v-icon color="primaryDark">mdi-phone-dial</v-icon></a
        >
        <v-spacer></v-spacer>
        <a
          class="link white--text mr-4"
          href="https://www.google.com/maps/place/Tesfa+Coffee+%7C+%E1%89%B0%E1%88%B5%E1%8D%8B+%E1%89%A1%E1%8A%93/@9.0384938,38.7628791,15z/data=!4m5!3m4!1s0x0:0xa54ca2c0b5fabe73!8m2!3d9.0384908!4d38.7628799"
          target="_blank"
          ><v-icon color="primaryDark">mdi-google-maps</v-icon>
        </a>
      </v-toolbar>
    </section>
    <section>
      <v-container grid-list-xs>
        <v-layout row wrap justify-center align-center>
          <v-flex xs12 sm12 md3 lg3 pa-2>
            <v-img
              src="../../assets/c1.jpg"
              height="100%"
              width="100%"
              contain
            ></v-img>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6 pa-2>
            <h1 class="text-left b display-2 primaryDark--text">
              Tesfa Coffee
            </h1>
            <p class="body-1">
              Tesfa Coffee is a family-run coffee roasting company based in
              Addis Ababa, Ethiopia. After 6 years of working on local Coffee
              business, we realized that our exeprience, customer feedback, our
              technical skills, and our Ethiopian value can be put togather to
              create a unique and high quality Coffee Brand that will transform
              customer's feeling to the hightest level. At the core of what we
              do is a better customer experience...
              <router-link class="link" to="/about">Read More...</router-link>
            </p></v-flex
          >
          <v-flex xs12 sm12 md3 lg3 pa-2>
            <v-img
              src="../../assets/product/p1.png"
              height="100%"
              width="100%"
              max-height="400px"
              contain
              @click="dialog = true"
              style="cursor: pointer"
            ></v-img>
            <div class="text-center pt-3">
              <router-link class="link b" to="/product"
                >See All Products</router-link
              >
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <section class="lg py-5">
      <v-container grid-list-xs>
        <v-layout row wrap justify-center>
          <v-flex xs12 sm12 md8 lg8 pa-2>
            <h1
              class="text-center display-2 b primaryDark--text d-none d-md-flex"
            >
              Taste our uniquely processed Coffee!
            </h1>
            <h1 class="text-center title b primaryDark--text d-md-none d-flex">
              Taste our uniquely processed Coffee!
            </h1>
            <p class="body-1 text-center">
              Our coffee is a high quality product with a consistent tastes.The
              coffee is packed with a high quality almunium coated foil that
              will keep the coffee safe for more than 2 years.
            </p>
          </v-flex>
          <v-flex xs12> </v-flex>
          <v-flex xs6 sm6 md2 lg2 pa-2>
            <v-btn
              block
              dark
              rounded
              color="primaryDark"
              class="text-capitalize"
              @click="$router.push({ name: 'order' })"
            >
              <v-icon small class="mr-2">mdi-cart</v-icon>
              Order Now</v-btn
            >
            <div class="smt b primaryDark--text text-center py-2">
              Get 1kg Coffee for free for every 10kg purchase
            </div>
          </v-flex>
          <v-flex xs6 sm6 md2 lg2 pa-2>
            <v-btn
              block
              dark
              outlined
              rounded
              color="primaryDark"
              class="text-capitalize"
            >
              <v-icon small class="mr-2">mdi-share</v-icon> Share</v-btn
            >
            <div class="smt b primaryDark--text text-center py-2">
              Share and get 1kg Coffee for free
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <section class="py-5">
      <Reason />
    </section>

    <section class="py-5 lg">
      <MediaCoverage />
    </section>

    <section class="py-5">
      <Customers />
    </section>

    <v-dialog
      v-model="dialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar flat dense dark color="primary">
          Sample Product
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-img
            src="../../assets/product/p1.png"
            height="100%"
            width="100%"
            contain
          ></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Reason from "./Reason.vue";
import MediaCoverage from "./MediaCoverage.vue";
import Customers from "./Customers.vue";
export default {
  components: { Reason, MediaCoverage, Customers },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>



<style lang="css" >
.subsc .theme--dark.v-label,
.subsc .theme--dark.v-input input {
  padding-left: 20px !important;
}
.contentCard {
  cursor: pointer;
}
.contentCard:hover {
  background: #f0f2f5 !important;
}
/* .bg1 {
  background-image: url("../../assets/bg1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
} */
</style>